body {
    background: #E6E6E6 !important;
}

.theme-bg-color {
    background: #E6E6E6 !important;
}

.theme-header {
    background: #E6E6E6 !important;
    border-bottom: 1px solid #313A47 !important;
}